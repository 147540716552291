<template>
  <v-list dense color="transparent" v-if="number">
    <v-list-item dense>
      <v-list-item-icon>
        <component :is="sIcon" size="2em" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="sCardNumber" />
        <v-list-item-action-text v-text="sCardName" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import creditCardType from "credit-card-type";
import masker from "vue-the-mask/src/masker";
import tokens from "vue-the-mask/src/tokens";
// import { VueMaskFilter } from "v-mask";

@Component
export default class CreditCardPreview extends Vue {
  @Prop(String) readonly number!: string;

  get obCardInfo() {
    return this.number && this.number.length > 4
      ? this.$_.first(creditCardType(this.number))
      : null;
  }

  get sCardName() {
    return this.obCardInfo ? this.obCardInfo.niceType : null;
  }

  get sCardNumber() {
    return masker(this.number, this.sMask, true, tokens);
  }

  get sIcon() {
    const arTypes = [
      "amex",
      "diners",
      "discover",
      "elo",
      "jcb",
      "maestro",
      "mastercard",
      "visa",
    ];
    const sCardType = this.obCardInfo ? this.obCardInfo.type : null;
    const sIcon =
      sCardType && arTypes.includes(sCardType) ? sCardType : "generic";

    return `icon-${sIcon}`;
  }

  get sMask() {
    return "#### #### #### ####";
  }
}
</script>
